<template>
    <div class="app-container">
        <el-row :gutter="12">
            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="card-header">
                        <span class="card-title">{{ $t('comm.question.iden-date') }}</span>

                        <span class="card-buttons">
                            <router-link :to="{ path: '/comunicare/intrebari/' }">
                                <el-button type="danger" icon="el-icon-back" size="mini" plain>
                                    {{ $t('general.back') }}
                                </el-button>
                            </router-link>
                            <el-button
                                v-if="!resolved"
                                @click="resolveQuestion"
                                type="success"
                                icon="el-icon-check"
                                size="mini"
                                plain
                                :disabled="!hasAnswer"
                            >
                                {{ $t('comm.question.mark-as-resolved') }}
                            </el-button>
                        </span>
                    </div>

                    <ul class="info-list">
                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="info" />
                                {{ $t('comm.question.unique-id') }}
                            </span>
                            <span class="float-right">
                                {{ id }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="user" />
                                {{ $t('comm.question.name') }}
                            </span>
                            <span class="float-right">
                                {{ nume }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="information-outline" />
                                {{ $t('comm.question.product-model') }}
                            </span>
                            <span class="float-right">
                                {{ products_model }}
                            </span>
                        </li>

                        <li>
                            <span class="list-title">
                                <svg-icon icon-class="list" />
                                {{ $t('comm.question.product-name') }}
                            </span>
                            <span class="float-right">
                                <el-link :href="products_url" type="primary" target="_blank">{{
                                    products_name
                                }}</el-link>
                            </span>
                        </li>
                    </ul>
                </el-card>
            </el-col>

            <el-col :span="24">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('comm.question.conv') }}</span>
                    </div>
                    <div>
                        <Conversation :messages="messages" :answer="!resolved" :upload="true" @send="sendMessage" />
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import communicationStore from '@/store/modules/communication';
import { get, call, sync } from 'vuex-pathify';
import { setParams } from '@/utils/index.js';

export default {
    props: ['iid'],
    components: {
        Conversation: () => import('@/components/Conversation/index.vue')
    },
    computed: {
        ...sync('communication/questions@single', {
            id: 'id',
            products_model: 'products_model',
            products_name: 'products_name',
            nume: 'nume',
            intrebare: 'intrebare',
            data: 'data',
            url: 'url',
            products_url: 'products_url',
            resolved: 'resolved',
            chat: 'chat'
        }),
        messages() {
            return this.chat.map((row) => {
                row.fromClient = row.tip_discutie;
                return row;
            });
        },
        hasAnswer() {
            if (this.chat.length > 0) {
                try {
                    let chat = JSON.parse(JSON.stringify(this.chat));
                    chat = chat.filter((row) => row.tip_discutie == 0);
                    return chat.length > 0;
                } catch (e) {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'hasAnswer',
                            params: this.chat
                        }
                    });
                }
            }
            return false;
        }
    },
    methods: {
        GetProductQuestion: call('communication/GetProductQuestion'),
        AnswerProductQuestion: call('communication/AnswerProductQuestion'),
        ResolveQuestion: call('communication/ResolveQuestion'),
        sendMessage(message, files) {
            let params = {
                questionID: this.iid,
                answer: message
            };
            params = setParams(params);

            if (files.length > 0) {
                for (const i in files) {
                    params.append('file_' + i, files[i]);
                }
            }

            this.AnswerProductQuestion(params)
                .then((response) => {
                    if (response.message.success) {
                        // Reload question
                        this.GetProductQuestion({
                            uniqueID: this.iid
                        });
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'answerProductQuestion',
                            params
                        }
                    });
                });
        },
        resolveQuestion() {
            this.ResolveQuestion({
                qid: this.iid
            })
                .then((response) => {
                    if (response.message.success) {
                        // Reload question
                        this.GetProductQuestion({
                            uniqueID: this.iid
                        });
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'resolveQuestion'
                        }
                    });
                });
        }
    },
    mounted() {
        this.GetProductQuestion({
            uniqueID: this.iid
        });
    },
    beforeCreate() {
        this.$store.registerModule('communication', communicationStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('communication');
    }
};
</script>

<style lang="scss" scoped>
.info-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
}

.info-list > li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 0;
}

.info-list > li + li {
    border-top: 1px #e8e8e8 solid;
}

.info-list > li:hover {
    background: #f9f9f9;
}

.info-list .list-title {
    display: inline-flex;
    align-items: center;
    color: #5f5f5f;
    min-width: 165px;
}

.info-list .list-title > i {
    color: #fb7621;
    width: 45px;
    text-align: center;
    font-size: 16px;
}

.info-list > li > span.float-right {
    text-align: right;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header .card-buttons {
    text-align: right;
}
</style>
